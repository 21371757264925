import Typewriter from 'typewriter-effect';

const TypewriterComponent = () => {
  return (
    <div className="flex justify-center text-center lg:text-left items-center lg:justify-start sm:w-full tracking-wide leading-none font-bold text-7xl lg:text-9xl relative">
      <div className="lg:relative w-11/12 lg:w-full sm:max-w-[510px]">
        {/* Static Text */}
        <div className="sm:invisible lg:visible lg:absolute lg:inset-0 flex items-center justify-center lg:justify-start text-[#f1f5f9]">
          FISH FOR A F***ING LAMBO!
        </div>
        {/* Typewriter Text */}
        <div className="absolute inset-0 flex items-center justify-center lg:justify-start text-black md:px-10 lg:px-0">
          <Typewriter
            options={{
              loop: true,
              deleteSpeed: 20,
            }}
            onInit={(typewriter) => {
              typewriter
                .pauseFor(1000)
                .typeString('FISH FOR A F***ING LAMBO!')
                .pauseFor(2000)
                .deleteAll()
                .start();
            }}
          />
        </div>
      </div>
    </div>
    //PREV VSION
    // <div className="flex justify-center text-center lg:text-left items-center lg:justify-start sm:w-full tracking-wide leading-none font-bold text-7xl lg:text-9xl">
    //         <div className="w-11/12 lg:w-full sm:max-w-[510px]">
    //           <Typewriter
    //             options={{
    //               loop: true,
    //               deleteSpeed: 20,
    //             }}
    //             onInit={(typewriter) => {
    //               typewriter
    //                 .pauseFor(1000)
    //                 .typeString('FISH FOR A F***ING LAMBO!')
    //                 .pauseFor(2000)
    //                 .deleteAll()
    //                 .start();
    //             }}
    //           />
    //         </div>
    // </div>
  );
};

export default TypewriterComponent;
