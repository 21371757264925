import { useState, useCallback } from 'react';

type UseModalStateReturn = {
  isInfoModalOpen: boolean;
  openInfoModal: () => void;
  closeInfoModal: () => void;
};

const useModalState = (): UseModalStateReturn => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const openInfoModal = useCallback(() => {
    if (!isInfoModalOpen) {
      setIsInfoModalOpen(true);
    }
  }, [isInfoModalOpen]);

  const closeInfoModal = useCallback(() => setIsInfoModalOpen(false), []);

  return { isInfoModalOpen, openInfoModal, closeInfoModal };
};

export default useModalState;
