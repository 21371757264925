import { useState, useEffect } from 'react';
import BLEMFsLogo from '../assets/BLEMFs_Logo.png';
import BLEMFsClosed from '../assets/BLEMFsClosed.png';
import BLEMFsSemiopen from '../assets/BLEMFsSemiopen.png';
import BLEMFsOpen from '../assets/BLEMFsOpen.png';
import MRR from '../assets/MRR.png';
import ME1 from '../assets/ME1.png';
import ME2 from '../assets/ME2.png';
import { Link } from 'react-router-dom';
import '../App.css'; // Import your CSS file for the font

export default function BLEMFs() {
  const [canImage, setCanImage] = useState(BLEMFsClosed);
  const canImages = [BLEMFsClosed, BLEMFsSemiopen, BLEMFsOpen, BLEMFsSemiopen];
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % canImages.length;
      setCanImage(canImages[index]);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      id="blemfs"
      className="bg-[#edff11] min-h-screen flex items-center justify-center px-4 py-8 sm:px-8"
    >
      <div className="max-w-screen-lg w-full flex flex-col items-center space-y-10 text-center">
        {/* Logo */}
        <img
          className="w-11/12 max-w-[150px] sm:max-w-[200px] h-auto"
          src={BLEMFsLogo}
          alt="BitBait BLEMFs"
        />
        {/* Changing Can Image */}
        <img
          className="w-11/12 max-w-[400px] sm:max-w-[500px] h-auto"
          src={canImage}
          alt="BitBait BLEMFs can"
        />

        {/* Text Section */}
        <p className="text-gray-800 text-lg md:text-2xl px-4 sm:px-12 leading-relaxed font-handwriting font-bold">
          BITBAIT BLEMFs is a social experiment, an “art” project to prove that
          this space & the dgens in it—are fvckn insane. We might not be able to
          claim “the art is the utility” this time… or maybe we can? Who the
          fvck knows anymore. <br />
          We’ve been listening, and it’s loud and clear: the space wants CHAOS.
          So, here it is.
        </p>
        <p className="text-gray-800 text-lg md:text-2xl px-4 sm:px-12 leading-tight font-handwriting font-bold">
          Additional details{' '}
          <a
            href="https://x.com/BitBait4Lambo/status/1857388935137103919"
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:opacity-60 transition-opacity"
          >
            HERE
          </a>
          .
        </p>

        {/* Mint Regret Repeat Image */}
        <img
          className="w-11/12 max-w-[300px] sm:max-w-[400px] h-auto"
          src={MRR}
          alt="Mint Regret Repeat"
        />

        <div>
          <a
            href="https://magiceden.io/collections/apechain/0xb7f09af1b524f9155eddbdc57138396291acf31b"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={isHovered ? ME2 : ME1}
              alt="Mint Some Bait"
              className="w-8 lg:w-12 h-auto transition-transform duration-300 hover:scale-105"
            />
          </a>
        </div>
        <Link to="/" aria-label="Navigate back to the homepage">
          <p className="text-gray-800 hover:opacity-60 text-base md:text-xl px-4 sm:px-12 pb-12 underline leading-relaxed font-handwriting font-bold">
            GO BACK HOME
          </p>
        </Link>
      </div>
    </div>
  );
}
