import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/Main';
import CansPage from './pages/Cans';
import BLEMFs from './pages/BLEMFs';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/cans" element={<CansPage />} />
        <Route path="/blemfs" element={<BLEMFs />} />
        {/* <Route path="/whitelistchecker" element={<WhitelistCheckerPage />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
