import React, { useState } from 'react';
import { FaXTwitter } from 'react-icons/fa6';
import { GiOpenedFoodCan } from 'react-icons/gi';
import { Link, useLocation } from 'react-router-dom';
import BitBait_Logo from '../assets/BitBait_Logo.png';
import BLEMFs_Logo from '../assets/BLEMFs_Logo.png';
import ME1 from '../assets/ME1.png';
import ME2 from '../assets/ME2.png';

const NavBar = () => {
  const location = useLocation();

  // Check if current path is /cans
  const isCansPage = location.pathname === '/cans';
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="w-full min-h-20 sm:min-h-26 sm:h-26 px-6 pt-4 sm:px-10 sm:pt-6 overflow-hidden flex justify-between items-center">
      <div>
        <Link to="/">
          <img
            className="w-16 lg:w-20 h-auto"
            src={BitBait_Logo}
            alt="BitBait bubblegum cans"
          />
        </Link>
      </div>
      <div className="flex">
        <div className="flex items-center justify-center pr-4 md:pr-6">
          <Link to="/blemfs">
            <img
              src={BLEMFs_Logo}
              alt="BLEMFs"
              className="w-16 lg:w-20 h-auto transition-transform duration-300 hover:scale-105 hover:opacity-60"
            />
          </Link>
        </div>
        <div className="h-6 border-l border-gray-400 mx-2"></div>
        <div className="flex items-center justify-center px-4 md:px-6">
          <a
            href="https://magiceden.io/collections/apechain/0x2F368ED870b65A55549EFDD8ec4332a81196b3Dd"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={isHovered ? ME2 : ME1}
              alt="Mint Some Bait"
              className="w-6 lg:w-10 h-auto transition-transform duration-300 hover:scale-105"
            />
          </a>
        </div>
        <Link
          className="flex items-center justify-center pr-4 md:pr-6 hover:text-[#b9babb] focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-accent"
          to="/cans"
        >
          <button>
            <GiOpenedFoodCan
              className={`block h-6 w-6 lg:h-8 lg:w-8 ${
                isCansPage ? 'text-[#c6d328]' : ''
              }`}
              aria-hidden="true"
            />
          </button>
        </Link>
        <div className="flex items-center justify-center pr-2 hover:text-[#b9babb] focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-accent">
          <a
            href="https://x.com/BitBait4Lambo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaXTwitter
              className="block h-6 w-6 lg:h-8 lg:w-8"
              aria-hidden="true"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
