import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Marquee from '../components/Marquee';
import CanClosedRed from '../assets/CanClosed-Red.png';
import CanSemiopenRed from '../assets/CanSemiopen-Red.png';
import CanOpenRed from '../assets/CanOpen-Red.png';
import CanClosedBlack from '../assets/CanClosed-Black.png';
import CanSemiopenBlack from '../assets/CanSemiopen-Black.png';
import CanOpenBlack from '../assets/CanOpen-Black.png';
import CanClosedOrange from '../assets/CanClosed-Orange.png';
import CanSemiopenOrange from '../assets/CanSemiopen-Orange.png';
import CanOpenOrange from '../assets/CanOpen-Orange.png';
import CanClosedPurple from '../assets/CanClosed-Purple.png';
import CanSemiopenPurple from '../assets/CanSemiopen-Purple.png';
import CanOpenPurple from '../assets/CanOpen-Purple.png';
import CanClosedGreen from '../assets/CanClosed-Green.png';
import CanSemiopenGreen from '../assets/CanSemiopen-Green.png';
import CanOpenGreen from '../assets/CanOpen-Green.png';
import CanClosedPink from '../assets/CanClosed-Pink.png';
import CanClosedBlue from '../assets/CanClosed-Blue.png';
import CanClosedWhite from '../assets/CanClosed-White.png';
import InfoModal from '../components/InfoModal';
import useModalState from '../utils/useModalState';

type CanColor =
  | 'red'
  | 'pink'
  | 'green'
  | 'orange'
  | 'blue'
  | 'purple'
  | 'black'
  | 'white'; // Added 'white' here

interface CanState {
  closed: string;
  semiOpen: string;
  open: string;
  isOpen: boolean;
}

type CanStates = Record<CanColor, CanState>;

export default function Cans() {
  const { isInfoModalOpen, openInfoModal, closeInfoModal } = useModalState();
  const initialState: CanStates = {
    red: {
      closed: CanClosedRed,
      semiOpen: CanSemiopenRed,
      open: CanOpenRed,
      isOpen: false,
    },
    pink: {
      closed: CanClosedPink,
      semiOpen: CanClosedPink,
      open: CanClosedPink,
      isOpen: false,
    },
    green: {
      closed: CanClosedGreen,
      semiOpen: CanSemiopenGreen,
      open: CanOpenGreen,
      isOpen: false,
    },
    orange: {
      closed: CanClosedOrange,
      semiOpen: CanSemiopenOrange,
      open: CanOpenOrange,
      isOpen: false,
    },
    blue: {
      closed: CanClosedBlue,
      semiOpen: CanClosedBlue,
      open: CanClosedBlue,
      isOpen: false,
    },
    purple: {
      closed: CanClosedPurple,
      semiOpen: CanSemiopenPurple,
      open: CanOpenPurple,
      isOpen: false,
    },
    black: {
      closed: CanClosedBlack,
      semiOpen: CanSemiopenBlack,
      open: CanOpenBlack,
      isOpen: false,
    },
    white: {
      closed: CanClosedWhite,
      semiOpen: CanClosedWhite,
      open: CanClosedWhite,
      isOpen: false,
    },
  };

  const [cans, setCans] = useState<CanStates>(initialState);

  const handleHover = (color: CanColor) => {
    if (color === 'pink' || color === 'white' || color === 'blue') return;
    setCans((prevState) => {
      if (!prevState[color].isOpen) {
        return {
          ...prevState,
          [color]: { ...prevState[color], closed: prevState[color].semiOpen },
        };
      }
      return prevState;
    });
  };

  const handleMouseLeave = (color: CanColor) => {
    if (color === 'pink' || color === 'white' || color === 'blue') return;
    setCans((prevState) => {
      if (!prevState[color].isOpen) {
        return {
          ...prevState,
          [color]: { ...prevState[color], closed: initialState[color].closed },
        };
      }
      return prevState;
    });
  };

  const handleClick = (color: CanColor) => {
    if (color === 'pink' || color === 'white' || color === 'blue') return;
    setCans((prevState) => {
      const isOpen = !prevState[color].isOpen;
      return {
        ...prevState,
        [color]: {
          ...prevState[color],
          closed: isOpen ? prevState[color].open : initialState[color].closed,
          isOpen: isOpen,
        },
      };
    });
  };

  return (
    <div id="cans">
      <div className="w-full h-screen flex flex-col">
        <NavBar />
        <InfoModal
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={closeInfoModal}
        />
        <div className="flex justify-center flex-wrap md:px-20 pb-28 lg:pt-6">
          {Object.keys(cans).map((color) => (
            <div key={color}>
              <img
                className="max-w-[340px] sm:max-w-[420px] h-auto"
                onMouseEnter={() => handleHover(color as CanColor)}
                onMouseLeave={() => handleMouseLeave(color as CanColor)}
                onClick={() => handleClick(color as CanColor)}
                src={cans[color as CanColor].closed}
                alt={`BitBait bubblegum can ${color}`}
              />
            </div>
          ))}
        </div>
        <Marquee />
      </div>
    </div>
  );
}
