import FastMarquee from 'react-fast-marquee';

const Marquee = () => {
  return (
    <div className="z-0 fixed inset-x-0 bottom-0 w-full bg-[#edff11] text-[#575c00] text-3xl py-3 overflow-hidden">
      <FastMarquee style={{ overflowY: 'hidden' }}>
        <div className="mx-5">NO UTILITY</div>
        <div className="mx-5">NO ROADMAP</div>
        <div className="mx-5 text-black">JUST COOL ART & GOOD VIBES</div>
        <div className="mx-5">NO UTILITY</div>
        <div className="mx-5">NO ROADMAP</div>
        <div className="mx-5 text-black">JUST COOL ART & GOOD VIBES</div>
      </FastMarquee>
    </div>
  );
};
export default Marquee;
