import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoMdClose } from 'react-icons/io';
import BitBait_Logo2 from '../assets/BitBait_Logo2.png';
import Comic1 from '../assets/Comic1.png';
import Comic2 from '../assets/Comic2.png';
import Comic3 from '../assets/Comic3.png';
import Comic4 from '../assets/Comic4.png';

interface InfoModalProps {
  isInfoModalOpen: boolean;
  setIsInfoModalOpen: (open: boolean) => void;
}

const InfoModal: React.FC<InfoModalProps> = ({
  isInfoModalOpen,
  setIsInfoModalOpen,
}) => {
  return (
    <Transition.Root show={isInfoModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsInfoModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative min-w-[334px] max-h-[90vh] overflow-y-auto transform rounded-lg bg-white px-8 pb-4 pt-5 text-left shadow-xl transition-all sm:px-20 sm:w-full sm:max-w-5xl">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                    onClick={() => setIsInfoModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <IoMdClose className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex flex-col items-center text-center">
                  <img
                    className="w-20 lg:w-40 h-auto"
                    src={BitBait_Logo2}
                    alt="BitBait bubblegum cans"
                  />
                  <div>
                    <p className="text-left text-gray-900 text-2xl md:text-3xl mb-6 leading-loose md:leading-loose lg:leading-loose">
                      What is BITBAIT, and what does “FISH FOR A F***ING LAMBO”
                      mean?
                    </p>
                    <p className="mb-8 text-left text-gray-700 text-base md:text-xl leading-loose md:leading-loose lg:leading-loose">
                      Think of it this way: the “f****** lambo” represents your
                      goals, and “fishing” symbolizes the action needed to reach
                      them. But what about the bait? Well, that’s the key. The
                      bait is your personal commitment—a token that embodies
                      your drive and determination to achieve your goals, or, as
                      we like to say, a f****** lambo! <br />
                      You might fish with just a rod, but the right bait makes
                      all the difference.
                    </p>
                    <img
                      className="w-full h-auto"
                      src={Comic1}
                      alt="BitBait - Vignette 1"
                    />
                    <img
                      className="w-full h-auto"
                      src={Comic2}
                      alt="BitBait - Vignette 2"
                    />
                    <img
                      className="w-full h-auto"
                      src={Comic3}
                      alt="BitBait - Vignette 3"
                    />
                    <img
                      className="w-full h-auto"
                      src={Comic4}
                      alt="BitBait - Vignette 4"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => setIsInfoModalOpen(false)}
                      className="my-8 rounded-md bg-black px-3 py-2 text-base md:text-xl text-white shadow-sm hover:bg-[#b9babb] focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-black"
                    >
                      CONTINUE
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InfoModal;
