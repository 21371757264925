import React, { useState, useCallback, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Marquee from '../components/Marquee';
import InfoModal from '../components/InfoModal';
import TypewriterComponent from '../components/TypewriterComponent';
import useModalState from '../utils/useModalState';
import CansIntroClosed from '../assets/CansIntroClosed.png';
import CansIntroSemiopen from '../assets/CansIntroSemiopen.png';
import CansIntroOpen from '../assets/CansIntroOpen.png';

export default function Home() {
  const [imageSrc, setImageSrc] = useState(CansIntroClosed);
  const [isOpen, setIsOpen] = useState(false);
  const { isInfoModalOpen, openInfoModal, closeInfoModal } = useModalState();

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('hasSeenModal');
    if (!hasSeenModal) {
      openInfoModal();
      sessionStorage.setItem('hasSeenModal', 'true');
    }
  }, [openInfoModal]);

  const handleHover = useCallback(() => {
    if (!isOpen) {
      setImageSrc(CansIntroSemiopen);
    }
  }, [isOpen]);

  const handleMouseLeave = useCallback(() => {
    if (!isOpen) {
      setImageSrc(CansIntroClosed);
    }
  }, [isOpen]);

  const handleClick = useCallback(() => {
    if (!isOpen) {
      setImageSrc(CansIntroOpen);
      setIsOpen(true);
    }
    const timer = setTimeout(() => {
      window.location.href = '/cans';
    }, 1000);
    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <div id="home">
      <div className="w-full h-screen flex flex-col">
        <NavBar />
        <InfoModal
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={closeInfoModal}
        />
        <div className="flex flex-col lg:flex-row h-auto lg:h-full pt-4 lg:pt-0 pb-4">
          <div className="w-full flex flex-col lg:flex-row justify-center lg:justify-end items-center">
            <p className="lg:hidden opacity-40">Press the can to open it!</p>
            <img
              className="w-11/12 max-w-[450px] sm:max-w-[500px] h-auto"
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}
              src={imageSrc}
              alt="BitBait bubblegum cans"
            />
          </div>
          <TypewriterComponent />
        </div>
        <div className="flex justify-center items-center">
          <p
            className="pb-24 text-lg md:text-2xl hover:opacity-60 cursor-pointer"
            onClick={openInfoModal}
          >
            What's BIT BAIT?
          </p>
        </div>

        <Marquee />
      </div>
    </div>
  );
}
